import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import * as paths from '../Strings/Paths';

import Homepage from "../Homepage";
import Rocket from "../CaseStudies/Rocket";
import PasswordProtectionSheet from "../Components/PasswordProtectionSheet";
import RocketCaseStudy from "../CaseStudies/Rocket/RocketCaseStudy";
import Crude from "../CaseStudies/Crude";
import AboutPage from "../CaseStudies/About";
import RatePlans from "../CaseStudies/Rate Plans";
import HometownHeroes from '../CaseStudies/Hometown Heroes';

const AppRoutes = () =>{

    return(
        <Router>
            <Routes>
                <Route path={paths.PROJECT_ROCKET_PATH} element={<Rocket/>}/>
                <Route path={paths.HOMEPAGE_PATH} element={<Homepage/>}/>
                <Route path={paths.PASSWORD_PROTECTED_PATH} element={<PasswordProtectionSheet />} />
                <Route path={paths.PROJECT_ROCKET_CASE_STUDY_PATH} element={<RocketCaseStudy />} />
                <Route path={paths.PROJECT_CRUDE_PATH} element={<Crude />} />
                <Route path={paths.PROJECT_OYO_PATH} element={<RatePlans />} />
                <Route path={paths.PROJECT_HOMETOWN_HEROES_PATH} element={<HometownHeroes />} />
                <Route path={paths.ABOUT_PATH} element={<AboutPage />} />
            </Routes>
        </Router>
    );
}

export default AppRoutes;