
// Homepage
export const HOMEPAGE_PATH = '/';

// Case studies
export const PROJECT_ROCKET_PATH = '/rocket-internship';
export const PROJECT_ROCKET_CASE_STUDY_PATH = '/rocket-case-study';
export const PROJECT_CRUDE_PATH = '/crude';
export const PROJECT_OYO_PATH = '/rate-plans';
export const PROJECT_COMFORT_OS_PATH = 'https://www.figma.com/proto/Wju68ugGC5on7bRSSSNfot/Comfort-OS-Case-Study?page-id=0%3A1&type=design&node-id=1-86&viewport=482%2C490%2C0.15&t=WqLz1XiMUUWOfYUi-1&scaling=scale-down&mode=design';
export const PROJECT_HOMETOWN_HEROES_PATH = '/hometown-heroes-community';

// Password protection
export const PASSWORD_PROTECTED_PATH = '/password-protected';

// Design competitions
export const COMPETITION_USABILATHON_PATH = 'https://docs.google.com/presentation/d/1ers3iKEcr6fcmlwcssXnC0sjMI1hPqFZrWoQranxGes/edit?usp=sharing'
export const COMPETITION_INFO_CHALLENGE_PATH = 'https://www.figma.com/proto/XOKjfAyuz5KNeBvpQ32Ocs/Info-Challenge-2023?page-id=158%3A2787&type=design&node-id=158-2794&viewport=3971%2C256%2C0.14&t=5AaJgZD1qh5lPLH0-1&scaling=scale-down&mode=design'
export const COMPETITION_PROTOTHON_PATH = 'https://www.figma.com/proto/jl6aSqUN8Vls4ZpMsio7PY/Protothon-2023?page-id=73%3A40882&type=design&node-id=90-44765&viewport=-1024%2C-225%2C0.03&t=eQVnFSYwS9pTZzUU-1&scaling=scale-down&mode=design'
export const COMPETITION_MAKEATHON_PATH = 'https://docs.google.com/presentation/d/1iAravtB7gW_SBDhm66oV2jkCQGoaAVArHBVaDbDay4M/edit?usp=sharing'
export const COMPETITION_USABILATHON2024_PATH = 'https://docs.google.com/presentation/d/11vht3W_mBlM2KFeesh6869IK9pmv7WqsWCOuCQRWOiM/edit?usp=sharing'

// About
export const ABOUT_PATH = '/about';

// Resume
// export const RESUME_PATH = '/Documents/resume_shaunak_bhanarkar.pdf';

// Crude Figma Community
export const CRUDE_FIGMA_COMMUNITY_PATH = 'https://www.figma.com/community/file/1239679378684173808';

// Crude prototypes
export const CRUDE_DESKTOP_PROTOTYPE_PATH = 'https://www.figma.com/proto/oVo4eZtB6qF3gh08KG2i53/Crude-Design-System?page-id=520%3A3766&node-id=408-1624&starting-point-node-id=408%3A1624&scaling=scale-down&mode=design&t=g17LupDgVlJDBxBH-1';
export const CRUDE_MOBILE_PROTOTYPE_PATH = 'https://www.figma.com/proto/oVo4eZtB6qF3gh08KG2i53/Crude-Design-System?page-id=520%3A3765&node-id=423-1752&starting-point-node-id=423%3A1752&scaling=scale-down&mode=design&t=X3ZgIRJvSRvX2ZAE-1';