import './UserQuote.css';

const UserQuote = ( {quote} ) => {

    return (
        <div className='user-quote-container'>
            <div className='apostrophe'>“</div>
            <div className='quote-outer-container'>
                <div className='quote-container rounded-xl'>
                    <p className='body grey'>{quote}</p>
                </div>
            </div>
        </div>
    );

}

export default UserQuote;