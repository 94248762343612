import '../CaseStudy.css';
import Header from "../../Components/Header";
import CaseStudyBanner from "../../Components/CaseStudyBanner";
import CrudeHero from '../../Images/Crude/crude_hero.png';
import * as crudeStrings from '../../Strings/CrudeStrings';
import Footer from "../../Components/Footer";
import OverviewSection from "../../Components/OverviewSection";
import ContentSection from "../../Components/ContentSection";
import { CRUDE_FIGMA_COMMUNITY_PATH } from "../../Strings/Paths";
import crudeStudyMaterial from '../../Images/Crude/crude_study_material.png';
import crudeStudyMailchimp from '../../Images/Crude/crude_study_mailchimp.png';
import crudeStudyCarbon from '../../Images/Crude/crude_study_carbon.png';
import crudeStudyAtlassian from '../../Images/Crude/crude_study_atlassian.png';
import crudeStudyFluent from '../../Images/Crude/crude_study_fluent.png';
import crudeStudySpectrum from '../../Images/Crude/crude_study_spectrum.png';
import crudeStudyGuardian from '../../Images/Crude/crude_study_guardian.png';
import crudeStudyFigma from '../../Images/Crude/crude_study_figma.png';
import crudeExplorationShape from '../../Images/Crude/crude_exploration_shape.png';
import crudeExplorationColor from '../../Images/Crude/crude_exploration_color.png';
import crudeExplorationSpacing from '../../Images/Crude/crude_exploration_spacing.png';
import crudeExplorationMoodboard from '../../Images/Crude/crude_moodboard.png';
import crudeTypeface from '../../Images/Crude/crude_typeface.png';
import crudeTypeScale from '../../Images/Crude/crude_type_scale.png';
import crudeColor from '../../Images/Crude/crude_color.png';
import crudeSpacing from '../../Images/Crude/crude_spacing.png';
import crudeMobileGrid from '../../Images/Crude/crude_mobile_grid.png';
import crudeDesktopFluidGrid from '../../Images/Crude/crude_desktop_fluid_grid.png';
import crudeDesktopFixedGrid from '../../Images/Crude/crude_desktop_fixed_grid.png';
import crudeBorderElevation from '../../Images/Crude/crude_border_elevation.png';
import crudeAccessibility from '../../Images/Crude/crude_accessibility.png';
import crudeColorAccessibility from '../../Images/Crude/crude_color_accessibility.png';
import crudeOtherAccessibility from '../../Images/Crude/crude_tappabale_area_text_size.png';
import crudeComponentButton from '../../Images/Crude/crude_component_button.png';
import crudePatterns from '../../Images/Crude/crude_patterns.png';
import crudeDesktopPrototype from '../../Images/Crude/crude_desktop_prototype.png';
import crudeMobilePrototype from '../../Images/Crude/crude_mobile_prototype.png';
import { CRUDE_DESKTOP_PROTOTYPE_PATH, CRUDE_MOBILE_PROTOTYPE_PATH } from "../../Strings/Paths";
import SectionHeader from '../../Components/SectionHeader';
import BulletPoint from '../../Components/BulletPoint';
import TextLink from '../../Components/TextLink';
import Metric from '../../Components/Metric';
import Picture from '../../Components/Picture';
import Card from '../../Components/Card';


const Crude = () => {
    return (
        <div>
            <Header />

            <div className="page-container">
                <div className="sections-container">


                    <div className='container my-0 mx-auto hero-container'>
                        <CaseStudyBanner
                            title={crudeStrings.CRUDE_BANNER_TITLE}
                            subtitle={crudeStrings.CRUDE_BANNER_SUBTITLE}
                            image={CrudeHero}
                            alt_text={crudeStrings.CRUDE_BANNER_ALT}
                        />
                        <OverviewSection
                            item_list={[
                                {
                                    'title': crudeStrings.CRUDE_OVERVIEW_TITLE1,
                                    'subtitle': crudeStrings.CRUDE_OVERVIEW_SUBTITLE1
                                },
                                {
                                    'title': crudeStrings.CRUDE_OVERVIEW_TITLE2,
                                    'subtitle': crudeStrings.CRUDE_OVERVIEW_SUBTITLE2
                                },
                                {
                                    'title': crudeStrings.CRUDE_OVERVIEW_TITLE3,
                                    'subtitle': crudeStrings.CRUDE_OVERVIEW_SUBTITLE3
                                },
                                {
                                    'title': crudeStrings.CRUDE_OVERVIEW_TITLE4,
                                    'subtitle': crudeStrings.CRUDE_OVERVIEW_SUBTITLE4
                                }
                            ]}
                        />

                    </div>

                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={crudeStrings.CRUDE_SECTION_OVERVIEW_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
                            <p className='body grey'>{crudeStrings.CRUDE_SECTION_OVERVIEW_CONTENT}</p>
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={crudeStrings.CRUDE_SECTION_DELIVERABLE_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <p className='body grey'>{crudeStrings.CRUDE_SECTION_DELIVERABLE_CONTENT}</p>
                            <div className="w-full flex flex-col gap-4 md:gap-8">
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_DELIVERABLE_BULLET_NUMBER1}
                                    subtitle={crudeStrings.CRUDE_SECTION_DELIVERABLE_BULLET_SUBTITLE1}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_DELIVERABLE_BULLET_NUMBER2}
                                    subtitle={crudeStrings.CRUDE_SECTION_DELIVERABLE_BULLET_SUBTITLE2}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_DELIVERABLE_BULLET_NUMBER3}
                                    subtitle={crudeStrings.CRUDE_SECTION_DELIVERABLE_BULLET_SUBTITLE3}
                                />
                            </div>
                            <TextLink
                                label={crudeStrings.CRUDE_SECTION_DELIVERABLE_LINK_LABEL}
                                path={CRUDE_FIGMA_COMMUNITY_PATH}
                                target={'_blank'}
                            />
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={crudeStrings.CRUDE_SECTION_IMPACT_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16">
                                <Metric
                                    number={crudeStrings.CRUDE_SECTION_IMPACT_METRIC_NUMBER1}
                                    label={crudeStrings.CRUDE_SECTION_IMPACT_METRIC_LABEL1}
                                    emoji={'🌟'}
                                />
                                <Metric
                                    number={crudeStrings.CRUDE_SECTION_IMPACT_METRIC_NUMBER2}
                                    label={crudeStrings.CRUDE_SECTION_IMPACT_METRIC_LABEL2}
                                    emoji={'📊'}
                                />
                                <Metric
                                    number={crudeStrings.CRUDE_SECTION_IMPACT_METRIC_NUMBER3}
                                    label={crudeStrings.CRUDE_SECTION_IMPACT_METRIC_LABEL3}
                                    emoji={'📌'}
                                />
                            </div>
                            <p className='body grey'>{crudeStrings.CRUDE_SECTION_IMPACT_CONTENT}</p>
                            <TextLink
                                label={crudeStrings.CRUDE_SECTION_IMPACT_LINK_LABEL}
                                path={CRUDE_FIGMA_COMMUNITY_PATH}
                                target={'_blank'}
                            />
                        </div>
                    </div>


                    <hr className="divider" />
                    
                    <div className='container'>
                        <ContentSection
                            title={crudeStrings.CRUDE_SECTION_PROCESS_TITLE}
                            type='wide'
                        />
                    </div>

                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={crudeStrings.CRUDE_SECTION_WHY_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <div className="w-full flex flex-col gap-4 md:gap-8">
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_WHY_BULLET_NUMBER1}
                                    subtitle={crudeStrings.CRUDE_SECTION_WHY_BULLET_SUBTITLE1}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_WHY_BULLET_NUMBER2}
                                    subtitle={crudeStrings.CRUDE_SECTION_WHY_BULLET_SUBTITLE2}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_WHY_BULLET_NUMBER3}
                                    subtitle={crudeStrings.CRUDE_SECTION_WHY_BULLET_SUBTITLE3}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={crudeStrings.CRUDE_SECTION_STUDY_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
                                <Card
                                    image={crudeStudyMaterial}
                                    alt_text={crudeStrings.CRUDE_SECTION_STUDY_CARD_ALT1}
                                    label={crudeStrings.CRUDE_SECTION_STUDY_CARD_LABEL1}
                                />
                                <Card
                                    image={crudeStudyMailchimp}
                                    alt_text={crudeStrings.CRUDE_SECTION_STUDY_CARD_ALT2}
                                    label={crudeStrings.CRUDE_SECTION_STUDY_CARD_LABEL2}
                                />
                                <Card
                                    image={crudeStudyCarbon}
                                    alt_text={crudeStrings.CRUDE_SECTION_STUDY_CARD_ALT3}
                                    label={crudeStrings.CRUDE_SECTION_STUDY_CARD_LABEL3}
                                />
                                <Card
                                    image={crudeStudyAtlassian}
                                    alt_text={crudeStrings.CRUDE_SECTION_STUDY_CARD_ALT4}
                                    label={crudeStrings.CRUDE_SECTION_STUDY_CARD_LABEL4}
                                />
                                <Card
                                    image={crudeStudyFluent}
                                    alt_text={crudeStrings.CRUDE_SECTION_STUDY_CARD_ALT5}
                                    label={crudeStrings.CRUDE_SECTION_STUDY_CARD_LABEL5}
                                />
                                <Card
                                    image={crudeStudySpectrum}
                                    alt_text={crudeStrings.CRUDE_SECTION_STUDY_CARD_ALT6}
                                    label={crudeStrings.CRUDE_SECTION_STUDY_CARD_LABEL6}
                                />
                                <Card
                                    image={crudeStudyGuardian}
                                    alt_text={crudeStrings.CRUDE_SECTION_STUDY_CARD_ALT7}
                                    label={crudeStrings.CRUDE_SECTION_STUDY_CARD_LABEL7}
                                />
                                <Card
                                    image={crudeStudyFigma}
                                    alt_text={crudeStrings.CRUDE_SECTION_STUDY_CARD_ALT8}
                                    label={crudeStrings.CRUDE_SECTION_STUDY_CARD_LABEL8}
                                />
                            </div>
                            <BulletPoint
                                title={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_TITLE}
                            />
                            <BulletPoint
                                number={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_NUMBER1}
                                subtitle={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_SUBTITLE1}
                            />
                             <BulletPoint
                                number={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_NUMBER2}
                                subtitle={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_SUBTITLE2}
                            />
                             <BulletPoint
                                number={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_NUMBER3}
                                subtitle={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_SUBTITLE3}
                            />
                             <BulletPoint
                                number={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_NUMBER4}
                                subtitle={crudeStrings.CRUDE_SECTION_STUDY_LEARNINGS_SUBTITLE4}
                            />
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_EXPLORATION_TITLE}
                                    type='short'
                                />
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16 flex flex-col gap-8 md:gap-16">
                            <Picture
                                image={crudeExplorationShape}
                                alt_text={crudeStrings.CRUDE_SECTION_EXPLORATION_SHAPE_ALT}
                                rounded={true}
                            />
                            <Picture
                                image={crudeExplorationColor}
                                alt_text={crudeStrings.CRUDE_SECTION_EXPLORATION_COLOR_ALT}
                                rounded={true}
                            />
                            <Picture
                                image={crudeExplorationSpacing}
                                alt_text={crudeStrings.CRUDE_SECTION_EXPLORATION_SPACING_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>


                    
                    


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={crudeStrings.CRUDE_SECTION_PRINCIPLES_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <div className="w-full flex flex-col gap-4 md:gap-8">
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_NUMBER1}
                                    title={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_TITLE1}
                                    subtitle={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_SUBTITLE1}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_NUMBER2}
                                    title={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_TITLE2}
                                    subtitle={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_SUBTITLE2}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_NUMBER3}
                                    title={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_TITLE3}
                                    subtitle={crudeStrings.CRUDE_SECTION_PRINCIPLES_CONTENT_SUBTITLE3}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_MOODBOARD_TITLE}
                                    type='short'
                                />
                            </div>
                            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                                <div className="w-full flex flex-col gap-4 md:gap-8">
                                    <BulletPoint
                                        title={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_TITLE}
                                    />
                                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER1}
                                        subtitle={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY11}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER2}
                                        subtitle={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY12}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER3}
                                        subtitle={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY13}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER4}
                                        subtitle={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY21}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER5}
                                        subtitle={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY22}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_NUMBER6}
                                        subtitle={crudeStrings.CRUDE_SECTION_MOODBOARD_CHARACTERISTICS_BODY23}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16">

                            <Picture
                                image={crudeExplorationMoodboard}
                                alt_text={crudeStrings.CRUDE_SECTION_MOODBOARD_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>

                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_TYPE_TITLE}
                                    type='short'
                                />
                            </div>
                            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                                <div className="w-full flex flex-col gap-4 md:gap-8">
                                    <BulletPoint
                                        title={crudeStrings.CRUDE_SECTION_TYPE_WHY_TITLE}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_TYPE_WHY_NUMBER1}
                                        title={crudeStrings.CRUDE_SECTION_TYPE_WHY_TITLE1}
                                        subtitle={crudeStrings.CRUDE_SECTION_TYPE_WHY_SUBTITLE1}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_TYPE_WHY_NUMBER2}
                                        title={crudeStrings.CRUDE_SECTION_TYPE_WHY_TITLE2}
                                        subtitle={crudeStrings.CRUDE_SECTION_TYPE_WHY_SUBTITLE2}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_TYPE_WHY_NUMBER3}
                                        title={crudeStrings.CRUDE_SECTION_TYPE_WHY_TITLE3}
                                        subtitle={crudeStrings.CRUDE_SECTION_TYPE_WHY_SUBTITLE3}
                                    />
                                    <BulletPoint
                                        number={crudeStrings.CRUDE_SECTION_TYPE_WHY_NUMBER4}
                                        title={crudeStrings.CRUDE_SECTION_TYPE_WHY_TITLE4}
                                        subtitle={crudeStrings.CRUDE_SECTION_TYPE_WHY_SUBTITLE4}
                                    />

                                </div>
                            </div>

                        </div>
                        <div className="w-full px-4 md:px-16">

                            <Picture
                                image={crudeTypeScale}
                                alt_text={crudeStrings.CRUDE_SECTION_TYPE_SCALE_ALT}
                                rounded={true}
                            />
                        </div>

                    </div>


                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_COLOR_TITLE}
                                    type='short'
                                />
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16 flex flex-col gap-8 md:gap-16">
                            <Picture
                                image={crudeColor}
                                alt_text={crudeStrings.CRUDE_SECTION_COLOR_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_GRIDS_SPACING_TITLE}
                                    type='short'
                                />
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16 flex flex-col gap-8 md:gap-16">
                            <Picture
                                image={crudeSpacing}
                                alt_text={crudeStrings.CRUDE_SECTION_SPACING_ALT}
                                rounded={true}
                            />
                            <div className='w-1/2 flex flex-col gap-4'>
                                <h5 className='h5 dark'>{crudeStrings.CRUDE_SECTION_GRIDS_MOBILE_TITLE}</h5>
                                <Picture
                                    image={crudeMobileGrid}
                                    alt_text={crudeStrings.CRUDE_SECTION_GRIDS_MOBILE_ALT}
                                    rounded={true}
                                />
                            </div>
                            <div className='w-full flex flex-col gap-4'>
                                <h5 className='h5 dark'>{crudeStrings.CRUDE_SECTION_GRIDS_DESKTOP_TITLE}</h5>
                                <Picture
                                    image={crudeDesktopFluidGrid}
                                    alt_text={crudeStrings.CRUDE_SECTION_GRIDS_DESKTOP_FLUID_ALT}
                                    rounded={true}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_BORDER_ELEVATION_TITLE}
                                    type='short'
                                />
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16 flex flex-col gap-8 md:gap-16">
                            <Picture
                                image={crudeBorderElevation}
                                alt_text={crudeStrings.CRUDE_SECTION_BORDER_ELEVATION_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_ACCESSIBILITY_TITLE}
                                    type='short'
                                />
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16 flex flex-col gap-8 md:gap-16">
                            <Picture
                                image={crudeAccessibility}
                                alt_text={crudeStrings.CRUDE_SECTION_ACCESSIBILITY_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_COMPONENTS_TITLE}
                                    type='short'
                                />
                            </div>
                            <div className='w-full md:w-8/12'>
                                <p className='body grey'>{crudeStrings.CRUDE_SECTION_COMPONENTS_CONTENT}</p>
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16 flex flex-col gap-8 md:gap-16">
                            <Picture
                                image={crudeComponentButton}
                                alt_text={crudeStrings.CRUDE_SECTION_COMPONENTS_BUTTON_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>

                    

                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_PATTERNS_TITLE}
                                    type='short'
                                />
                            </div>
                        </div>
                        <div className="w-full px-4 md:px-16 flex flex-col gap-8 md:gap-16">
                            <Picture
                                image={crudePatterns}
                                alt_text={crudeStrings.CRUDE_SECTION_PATTERNS_ALT}
                                rounded={true}
                            />
                        </div>
                    </div>


                    
                    <div className="container w-full flex flex-col gap-4 md:gap-16">
                        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                            <div className="w-full md:w-4/12">
                                <SectionHeader
                                    title={crudeStrings.CRUDE_SECTION_WEBSITE_TITLE}
                                    type='short'
                                />
                            </div>
                            <div className='w-full md:w-8/12 flex flex-col gap-8 md:gap-16'>
                                <p className='body grey'>{crudeStrings.CRUDE_SECTION_WEBSITE_CONTENT}</p>
                                <div className='w-full flex flex-row gap-4 md:gap-16'>
                                    <Card
                                        image={crudeDesktopPrototype}
                                        alt_text={crudeStrings.CRUDE_SECTION_WEBSITE_DESKTOP_ALT}
                                        label={crudeStrings.CRUDE_SECTION_WEBSITE_DESKTOP_LABEL}
                                        path={CRUDE_DESKTOP_PROTOTYPE_PATH}
                                    />
                                    <Card
                                        image={crudeMobilePrototype}
                                        alt_text={crudeStrings.CRUDE_SECTION_WEBSITE_MOBILE_ALT}
                                        label={crudeStrings.CRUDE_SECTION_WEBSITE_MOBILE_LABEL}
                                        path={CRUDE_MOBILE_PROTOTYPE_PATH}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={crudeStrings.CRUDE_SECTION_LEARNINGS_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <div className="w-full flex flex-col gap-4 md:gap-8">
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_LEARNINGS_NUMBER1}
                                    subtitle={crudeStrings.CRUDE_SECTION_LEARNINGS_SUBTITLE1}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_LEARNINGS_NUMBER2}
                                    subtitle={crudeStrings.CRUDE_SECTION_LEARNINGS_SUBTITLE2}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_LEARNINGS_NUMBER3}
                                    subtitle={crudeStrings.CRUDE_SECTION_LEARNINGS_SUBTITLE3}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
                        <div className="w-full md:w-4/12">
                            <SectionHeader
                                title={crudeStrings.CRUDE_SECTION_WHAT_IF_TITLE}
                                type='short'
                            />
                        </div>
                        <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
                            <p className='body grey'>{crudeStrings.CRUDE_SECTION_WHAT_IF_CONTENT}</p>
                            <div className="w-full flex flex-col gap-4 md:gap-8">
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_WHAT_IF_NUMBER1}
                                    subtitle={crudeStrings.CRUDE_SECTION_WHAT_IF_SUBTITLE1}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_WHAT_IF_NUMBER2}
                                    subtitle={crudeStrings.CRUDE_SECTION_WHAT_IF_SUBTITLE2}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_WHAT_IF_NUMBER3}
                                    subtitle={crudeStrings.CRUDE_SECTION_WHAT_IF_SUBTITLE3}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_WHAT_IF_NUMBER4}
                                    subtitle={crudeStrings.CRUDE_SECTION_WHAT_IF_SUBTITLE4}
                                />
                                <BulletPoint
                                    number={crudeStrings.CRUDE_SECTION_WHAT_IF_NUMBER5}
                                    subtitle={crudeStrings.CRUDE_SECTION_WHAT_IF_SUBTITLE5}
                                />
                            </div>
                        </div>
                    </div>







                </div>

                <Footer />
            </div>
        </div>
    );
}

export default Crude;