import Header from './Components/Header';
import SectionHeader from './Components/SectionHeader';
import './Homepage.css';
import ProjectsGrid from './Components/ProjectsGrid';
import CompetitionsGrid from './Components/CompetitionsGrid';
import { NAV_WORK_LABEL } from './Strings/GlobalStrings';
import * as homepageStrings from './Strings/HomepageStrings.js';
import Footer from './Components/Footer';

const Homepage = () => {

  return (
    <div>

      <Header
        selected_link_label={NAV_WORK_LABEL}
      />

      <div className='welcome-container'>

        <div className='container my-0 mx-auto'>

        <div className='flex flex-col gap-8 px-4 md:px-16'>

        <div className='w-full md:w-9/12 flex flex-col gap-0'>

          <h1 className='h1 indigo'>{homepageStrings.HOMEPAGE_NAME}</h1>



          <h3 className='h3 dark'>{homepageStrings.HOMEPAGE_ROLE}</h3>


        </div>
        <div className='w-full grid grid-cols-1 gap-4 md:grid-cols-3'>
          <div className='col-span-1'>
            <div className='h3 dark'>💼</div>
            <div className='h4 dark'>3 years experience</div>
          </div>
          <div className='col-span-1'>
            <div className='h3 dark'>🎓</div>
            <div className='h4 dark'>Master's in HCI</div>
          </div>
          <div className='col-span-1'>
            <div className='h3 dark'>👨‍💻</div>
            <div className='h4 dark'>Developer turned designer</div>
          </div>
        </div>
        </div>

</div>


        <div className="scroll-container">
          <div className="scroll">
            <div className="right-to-left">
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS1}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS2}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS1}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS2}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS1}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS2}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS1}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS2}</p>
            </div>

          </div>
        </div>

      </div>

      <div className='homepage-container'>

        <div className='container my-0 mx-auto homepage-content-container'>

          <div className='featured-section'>
            <SectionHeader
              title={homepageStrings.FEATURED_SECTION_HEADING}
              type='short'
            />

            <ProjectsGrid />
          </div>

          <div className='featured-section'>
            <SectionHeader
              title={homepageStrings.COMPETITION_SECTION_HEADING}
              type='short'
            />

            <CompetitionsGrid />
          </div>

        </div>

        <Footer />

      </div>

    </div>
  );
}

export default Homepage;
