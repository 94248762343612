import { HOMEPAGE_PATH } from '../Strings/Paths';
import './TextLink.css';
import { Link } from 'react-router-dom';

const TextLink = ({ label, path, target }) => {
    if (!label) {
        label = '';
    }
    if (!path) {
        path = HOMEPAGE_PATH;
    }
    if (!target) {
        target = '_self';
    }

    return (
        <Link to={path} target={target}>
            <div className='link-container'>
                <p className='body light'>{label}</p>
            </div>
        </Link>
    );

}

export default TextLink;
