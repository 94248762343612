import Header from "../../Components/Header";
import * as rocketCaseStudyStrings from '../../Strings/RocketCaseStudyStrings';
import rocketCaseStudyHero from '../../Images/Rocket/rocket_case_study_hero.png';
import rocketCaseStudyProblem from '../../Images/Rocket/rocket_case_study_problem.png';
import rocketCaseStudySolution from '../../Images/Rocket/rocket_case_study_solution.png';
import rocketCaseStudyRmoRds from '../../Images/Rocket/rocket_case_study_rmo_rds.png';
import rocketCaseStudyAudit from '../../Images/Rocket/rocket_case_study_audit.png';
import rocketCaseStudyExplorations from '../../Images/Rocket/rocket_case_study_explorations.png';
import rocketCaseStudyProposedSolution from '../../Images/Rocket/rocket_case_study_proposed_solution.png';
import rocketCaseStudyTaskFlow from '../../Images/Rocket/rocket_case_study_task_flow.png';
import rocketCaseStudyUXConsiderations from '../../Images/Rocket/rocket_case_study_ux_considerations.png';
import rocketCaseStudyMobileDesigns from '../../Images/Rocket/rocket_case_study_mobile_designs.png';
import iconCheck from '../../Icons/icon_check.svg';
import iconExclaimation from '../../Icons/icon_exclaimation.svg';
import CaseStudyBanner from "../../Components/CaseStudyBanner";
import OverviewSection from "../../Components/OverviewSection";
import ContentSection from "../../Components/ContentSection";
import Footer from "../../Components/Footer";
import '../CaseStudy.css';
import SectionHeader from "../../Components/SectionHeader";
import Picture from "../../Components/Picture";
import Metric from "../../Components/Metric";
import BulletPoint from "../../Components/BulletPoint";
import UserQuote from "../../Components/UserQuote";
import Card from "../../Components/Card";
import rocketCaseStudyExplorationBoard from '../../Images/Rocket/rocket_case_study_exploration_board.png';
import rocketCaseStudyExplorationCarousel from '../../Images/Rocket/rocket_case_study_exploration_carousel.png';
import rocketCaseStudyExplorationNewnessBased from '../../Images/Rocket/rocket_case_study_exploration_newness_based.png';
import rocketCaseStudyExplorationOverviewDetail from '../../Images/Rocket/rocket_case_study_exploration_overview_detail.png';
import rocketCaseStudyExplorationTable from '../../Images/Rocket/rocket_case_study_exploration_table.png';
import rocketCaseStudyExplorationTaskBased from '../../Images/Rocket/rocket_case_study_exploration_task_based.png';


const RocketCaseStudy = () => {

  const rocketCaseStudyOverviewItems = [
    {
      'title': rocketCaseStudyStrings.ROCKET_CASE_STUDY_OVERVIEW_TITLE1,
      'subtitle': rocketCaseStudyStrings.ROCKET_CASE_STUDY_OVERVIEW_SUBTITLE1
    },
    {
      'title': rocketCaseStudyStrings.ROCKET_CASE_STUDY_OVERVIEW_TITLE2,
      'subtitle': rocketCaseStudyStrings.ROCKET_CASE_STUDY_OVERVIEW_SUBTITLE2
    },
    {
      'title': rocketCaseStudyStrings.ROCKET_CASE_STUDY_OVERVIEW_TITLE3,
      'subtitle': rocketCaseStudyStrings.ROCKET_CASE_STUDY_OVERVIEW_SUBTITLE3
    },
    {
      'title': rocketCaseStudyStrings.ROCKET_CASE_STUDY_OVERVIEW_TITLE4,
      'subtitle': rocketCaseStudyStrings.ROCKET_CASE_STUDY_OVERVIEW_SUBTITLE4
    }
  ];


  return (
    <div>

      <Header />

      <div className="page-container">
        <div className="sections-container">


          <div className='container my-0 mx-auto hero-container'>
            <CaseStudyBanner
              title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_BANNER_TITLE}
              subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_BANNER_SUBTITLE}
              image={rocketCaseStudyHero}
              alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_BANNER_ALT}
            />
            <OverviewSection
              item_list={rocketCaseStudyOverviewItems}
            />


          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_OVERVIEW_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <p className="body grey">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_OVERVIEW_CONTENT}</p>
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROBLEM_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <p className="body grey">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROBLEM_CONTENT}</p>
              <Picture
                image={rocketCaseStudyProblem}
                alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROBLEM_ALT}
                rounded={true}
              />
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_SOLUTION_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <p className="body grey">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_SOLUTION_CONTENT}</p>
              <Picture
                image={rocketCaseStudySolution}
                alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_SOLUTION_ALT}
                rounded={true}
              />
            </div>
          </div>



          <hr className="divider" />

          <div className="container">
            <ContentSection
              title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROCESS_TITLE}
              type='wide'
            />
          </div>








          {/* <ContentSection
            title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_REQ_TITLE}
            type='short'
            content_array={[
              {
                'content_type': 'image',
                'image': rocketCaseStudyRmoRds,
                'alt_text': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_REQ_ALT,
                'content': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_REQ_CONTENT,
                'image_columns': '6',
                'content_columns': '6'
              }
            ]}
          />

          <ContentSection
            title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INITIATIVE_TITLE}
            type='short'
            content_array={[
              {
                'content_type': 'text_equal_width',
                'text_array': [
                  {
                    'title': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INITIATIVE_CONTENT_PROACTIVE_TITLE,
                    'subtitle': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INITIATIVE_CONTENT_PROACTIVE_SUBTITLE
                  },
                  {
                    'title': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INITIATIVE_CONTENT_COMMUNICATION_TITLE,
                    'subtitle': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INITIATIVE_CONTENT_COMMUNICATION_SUBTITLE
                  }
                ]
              }
            ]}
          /> */}


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_WHY_TITLE}
                label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_WHY_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <div className="w-full flex flex-row gap-16">
                <Metric
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_WHY_METRIC_VISITS_NUMBER}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_WHY_METRIC_VISITS_LABEL}
                />
                <Metric
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_WHY_METRIC_VISITORS_NUMBER}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_WHY_METRIC_VISITORS_LABEL}
                />
              </div>
              <p className="body grey">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_WHY_CONTENT}</p>
            </div>
          </div>





          {/* <ContentSection
            title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LACK_TITLE}
            type='short'
            content_array={[
              {
                'content_type': 'text',
                'text': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LACK_CONTENT1
              },
              {
                'content_type': 'bullet_points',
                'bullet_points': [
                  {
                    'number': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LACK_BULLET_NUMBER1,
                    'subtitle': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LACK_BULLET_SUBTITLE1
                  },
                  {
                    'number': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LACK_BULLET_NUMBER2,
                    'subtitle': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LACK_BULLET_SUBTITLE2
                  }
                ]
              },
              {
                'content_type': 'text',
                'text': rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LACK_CONTENT2
              }

            ]}
          /> */}



          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_TITLE}
                label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <p className="body grey">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_CONTENT}</p>
              <div className="w-full flex flex-row gap-16">
                <Metric
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_METRIC_PARTICIPANTS_NUMBER}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_METRIC_PARTICIPANTS_LABEL}
                />
                <Metric
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_METRIC_ISSUES_NUMBER}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_METRIC_ISSUES_LABEL}
                />
              </div>
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <div className="flex flex-col md:flex-row md:gap-8 md:items-center">
                  <div className="w-full md:w-1/2">
                    <BulletPoint
                      title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_OVERLOAD_TITLE}
                      subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_OVERLOAD_SUBTITLE}
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <UserQuote
                      quote={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_OVERLOAD_QUOTE}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:gap-8 md:items-center">
                  <div className="w-full md:w-1/2">
                    <BulletPoint
                      title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_CLARITY_TITLE}
                      subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_CLARITY_SUBTITLE}
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <UserQuote
                      quote={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_CLARITY_QUOTE}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:gap-8 md:items-center">
                  <div className="w-full md:w-1/2">
                    <BulletPoint
                      title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_DIRECTION_TITLE}
                      subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_DIRECTION_SUBTITLE}
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <UserQuote
                      quote={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_INTERVIEWS_QUOTES_DIRECTION_QUOTE}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:gap-16">
            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
              <div className="w-full">
                <SectionHeader
                  title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_AUDIT_TITLE}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_AUDIT_LABEL}
                  type='short'
                />
              </div>
            </div>
            <div className="w-full px-4 md:px-16">
              <Picture
                image={rocketCaseStudyAudit}
                alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_AUDIT_ALT}
                rounded={true}
              />
            </div>
          </div>

          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_USER_GOALS_TITLE}
                label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_USER_GOALS_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col md:flex-row gap-4 md:gap-16">
              <BulletPoint
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_GOALS_USER_GOAL_TITLE1}
                subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_GOALS_USER_GOAL_SUBTITLE1}
              />
              <BulletPoint
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_GOALS_USER_GOAL_TITLE2}
                subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_GOALS_USER_GOAL_SUBTITLE2}
              />
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_BUSINESS_GOALS_TITLE}
                label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_BUSINESS_GOALS_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col md:flex-row gap-4 md:gap-16">
              <BulletPoint
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_GOALS_BUSINESS_GOAL_TITLE}
                subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_GOALS_BUSINESS_GOAL_SUBTITLE}
              />
              <div className='w-full hidden md:block'></div>
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_OBJECTIVES_TITLE}
                label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_OBJECTIVES_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col md:flex-row gap-4 md:gap-16">
              <BulletPoint
                number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_OBJECTIVES_TITLE1}
                subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_OBJECTIVES_SUBTITLE1}
              />
              <BulletPoint
                number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_OBJECTIVES_TITLE2}
                subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_OBJECTIVES_SUBTITLE2}
              />
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TITLE}
                label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_LABEL}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-8">
              <div className="w-full grid grid-cols-2 gap-4 md:gap-8">
                <Card
                  image={rocketCaseStudyExplorationCarousel}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CAROUSEL_ALT}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CAROUSEL_LABEL}
                />
                <Card
                  image={rocketCaseStudyExplorationTable}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TABLE_ALT}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TABLE_LABEL}
                />
                <Card
                  image={rocketCaseStudyExplorationBoard}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_BOARD_ALT}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_BOARD_LABEL}
                />
                <Card
                  image={rocketCaseStudyExplorationTaskBased}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TASK_BASED_ALT}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_TASK_BASED_LABEL}
                />
                <Card
                  image={rocketCaseStudyExplorationNewnessBased}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_NEWNESS_BASED_ALT}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_NEWNESS_BASED_LABEL}
                />
                <Card
                  image={rocketCaseStudyExplorationOverviewDetail}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_OVERVIEW_DETAIL_ALT}
                  label={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_OVERVIEW_DETAIL_LABEL}
                />
            </div>
              <p className="body grey">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CONTENT}</p>
              <div className="w-full flex flex-col md:flex-row gap-8">
              <BulletPoint
                title={'✅'}
                subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CONTENT_SUBTITLE1}
              />
              <BulletPoint
                title={'✅'}
                subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CONTENT_SUBTITLE2}
              />
              <BulletPoint
                title={'🚫'}
                subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_EXPLORATIONS_CONTENT_SUBTITLE3}
              />
              </div>
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:gap-16">
            <div className="w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
              <div className="w-full md:w-4/12">
                <SectionHeader
                  title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_TITLE}
                  type='short'
                />
              </div>
            </div>
            <div className="w-full px-4 md:px-16">
              <Picture
                image={rocketCaseStudyProposedSolution}
                alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_ALT}
                rounded={true}
              />
            </div>
            <div className="w-full flex flex-col gap-4 md:gap-8 px-4 md:px-16">
              <div className="w-full">
                <h4 className="h4 dark">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_FLOW_TITLE}</h4>
                <h6 className="h6 dark">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_FLOW_SUBTITLE}</h6>
              </div>
              <div className="w-full">
                <Picture
                  image={rocketCaseStudyTaskFlow}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_FLOW_ALT}
                  rounded={true}
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-4 md:gap-8 px-4 md:px-16">
              <div className="w-full">
                <h4 className="h4 dark">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_CONSIDERATIONS_TITLE}</h4>
                <h6 className="h6 dark">{rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_CONSIDERATIONS_SUBTITLE}</h6>
              </div>
              <div className="w-full flex flex-col gap-4 md:gap-16">
                <Picture
                  image={rocketCaseStudyUXConsiderations}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_CONSIDERATIONS_ALT}
                  rounded={true}
                />
                <Picture
                  image={rocketCaseStudyMobileDesigns}
                  alt_text={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_PROPOSED_MOBILE_DESIGNS_ALT}
                  rounded={true}
                />
              </div>
            </div>
          </div>




          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <BulletPoint
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_NUMBER1}
                  subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_SUBTITLE1}
                />
                <BulletPoint
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_NUMBER2}
                  subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_SUBTITLE2}
                />
                <BulletPoint
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_NUMBER3}
                  subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_SUBTITLE3}
                  body={[
                    rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_BODY31,
                    rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_BODY32,
                    rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_BODY33,
                    rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_NEXT_BULLET_BODY34
                  ]}
                  body_padding={true}
                />
              </div>
            </div>
          </div>


          <div className="container w-full flex flex-col gap-4 md:flex-row md:gap-16 px-4 md:px-16">
            <div className="w-full md:w-4/12">
              <SectionHeader
                title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_TITLE}
                type='short'
              />
            </div>
            <div className="w-full md:w-8/12 flex flex-col gap-4 md:gap-16">
              <div className="w-full flex flex-col gap-4 md:gap-8">
                <BulletPoint
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_NUMBER1}
                  title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_TITLE1}
                  subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_SUBTITLE1}
                />
                <BulletPoint
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_NUMBER2}
                  title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_TITLE2}
                  subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_SUBTITLE2}
                />
                <BulletPoint
                  number={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_NUMBER3}
                  title={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_TITLE3}
                  subtitle={rocketCaseStudyStrings.ROCKET_CASE_STUDY_SECTION_LEARNINGS_BULLET_SUBTITLE3}
                />
              </div>
            </div>
          </div>




            






        </div>

        <Footer />
      </div>


    </div>
  );
}

export default RocketCaseStudy;